import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from '../../../../components'
import './filter.scss'
import FilterCiclo from './filterCiclo'
import FilterData from './filterData'
import { filterHandle, putFiltersProvisorio, setFiltersProvisorio } from '../redux/plano7diasActions'

export default () => {
  const dispatch = useDispatch()
  const { projeto, empresa, filters, listas: {typeSearch}, ciclos, grupo, sistema } = useSelector(state=> state.layoutState)
  const { filtersProvisorio } = useSelector(state=> state.plano7diasState)


  useEffect(()=> {
    let filtros = filters
    if (!filters?.ciclo?.anoMes  && ciclos.length) {
      filtros = {...filtersProvisorio, ...filtros, ciclo: ciclos[ciclos.length - 1]}
    }
    dispatch(setFiltersProvisorio(filtros))
  }, [ciclos])
  
  // useEffect(()=> {
  //   console.log(typeSearch[1], 'typeSearchtypeSearchtypeSearchtypeSearchtypeSearch');
    
  //   dispatch(putFiltersProvisorio({name: 'typeSelect', value: typeSearch[1]}))
  // }, [])

  const handleSearching = (params) => {
    handleSearchingValid(params)
    // if (params.typeSelect.id === 'Ciclo') {
    // }
    // if (params.typeSelect.id === 'Data') {
    //   if (params.dataInicial && params.dataFinal) {
    //     handleSearchingValid(params)
    //   }
    // }
  }
  
  const handleSearchingValid = (params) => {
      dispatch(filterHandle({projetoID: projeto.id, empresaId: empresa.empresaId, grupo, params, filtersProvisorio, typeSelect: params.typeSelect, idioma: sistema.idiomaIdValue}))
  }
  
  const contentFilterType = (e) => {
    console.log(e, 'contentFilterType');
    
    switch (e?.id) {
      case 'Ciclo':
        return <FilterCiclo />;
      case 'Data':
        return <FilterData />;
      default:
        return ;
    }
  }
  
  console.log(filtersProvisorio, 'filtersProvisorio');
  
  return (
    <div className='box-swot-search box-content'>
      <div className='box-swot-search-box'>
        <div className='box-swot-search-type'>
          {
            typeSearch.filter(f=> f.id !== 'Ciclo').map((type)=> {
              return <Button
                color={'primary'}
                type={type.id === typeSearch[1]?.id?'btn':'link'}
                size='small'
                onClick={()=> dispatch(putFiltersProvisorio({name: 'typeSelect', value: type}))}
                key={type.id}
              >
                {type.label}
              </Button> 
            })
          }
          <p>{typeSearch[1]?.text}</p>
          {/* <p>{filtersProvisorio?.typeSelect?.text}</p> */}
        </div>
        <div>
          {contentFilterType(typeSearch[1])}
        </div>

      </div>
      <div className='box-swot-search-action'>
        <Button
          color='primary'
          size='large'
          onClick={()=> handleSearching({filtersProvisorio, typeSearch: typeSearch[1] })}
        >Pesquisar</Button>          
      </div>
    </div>
  )
}
