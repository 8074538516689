import { dateCustom } from '../../../../components';
import { paramsApi } from '../../../../helpers';
import api from '../../../../helpers/api';
import apiOld from '../../../../helpers/apiOld';
import { setFiltered } from '../../../../layout/redux/layoutActions';


const  generateDataName = (name, yrange) => {
    let y = Math.floor(Math.random() * (yrange.max - yrange.min + 1)) + yrange.min;
    return { x: name, y: y };
}
const nLojas = 5
const labelsType = [ 'Satisfação geral', 'Insatisfação geral', 'Pilares', 'Indicadores' ]
const listSwots = [
  {
    id: 'forca',
    title: 'Força',
    labels: labelsType,
    type: { min: 0, max: 100 },
    lojas: Array(nLojas).fill().map((_, i)=> `Loja ${i+1}`)
  },
  {
    id: 'fraqueza',
    title: 'Fraqueza',
    labels: labelsType,
    type: { min: 0, max: 100 },
    lojas: Array(nLojas).fill().map((_, i)=> `Loja ${i+1}`)
  },
  {
    id: 'ameaca',
    title: 'Ameaça', 
    labels: labelsType,
    type: { min: 0, max:100 },
    lojas: Array(nLojas).fill().map((_, i)=> `Loja ${i+1}`)
  },
  {
    id: 'oportunidade',
    title: 'Oportunidade',
    labels: labelsType,
    type: { min: 0, max: 100 },
    lojas: Array(nLojas).fill().map((_, i)=> `Loja ${i+1}`)
  }
]

const listasMock = {
  swots: listSwots.map(l=> ({
    id: l.id,
    title: l.title,
    swot: l.labels.map(label=> ({
      name: label,
      data: l.lojas.map(loja=> {
        return generateDataName(loja,{ min: 0, max: 100 })
      })
    }))
  }))
}

export const setTotalAvaliacao = payload => ({
  type: 'INFO_SWOTS_TOTAL_AVALIACAO',
  payload
})

export const setFiltersProvisorio = payload => ({
  type: 'INFO_FILTERS_SWOTS',
  payload
})

export const putFiltersProvisorio = payload => ({
  type: 'CHANGE_FILTERS_SWOTS',
  payload
})

export const cleanFiltersProvisorio = () => ({
  type: 'CLEAN_FILTERS_SWOTS',
})


export const setListarSwots = payload => ({
  type: 'LIST_SWOTS',
  payload
})

export const setLoadList = payload => ({
  type: 'LOAD_LIST_SWOTS',
  payload
})

// export const listarSwots = e => {

//   return dispatch => dispatch([
//     setListarSwots(listasMock.swots),
//   ])

//   let params = paramsApi({})
//   return dispatch => {
//     api.get(`urlendpoint${params}`)
//     .then(result => {
//       dispatch(setListarSwots(result.data))
//     })
//     .catch(err => {
//         console.error(err);
//     });
//   }
// }

// export const filterHandle = ({projetoID, params, filterActive, typeSelect}) => {
//   return dispatch => dispatch([
//     setFiltered(params),
//     setListarSwots(listasMock.swots)
//   ])


//   let paramsf = paramsApi({})
//   return dispatch => {
//     api.get(`urlendpoint${params}`)
//     .then(result => {
//       dispatch([
//         setListarSwots(listasMock.graficos)
//       ])
//     })
//     .catch(err => {
//         console.error(err);
//     });
//   }
// }



export const filtersCustom = ({filters, typeSelect}) => {
  let paramsFilter = {}
  const dataAtual = new Date()
  if (typeSelect?.id === 'Ciclo') {
    if (filters?.ciclo?.mesAno) {
      paramsFilter = {
        intervalo: `${filters.ciclo.anoMes}`,
      }
    }
  }
  if (typeSelect?.id === 'Data') {
    paramsFilter = {
      intervalo: `${dateCustom(filters.dataInicial).data} ${dateCustom(filters.dataFinal).data}`,
    }
  }
  if (filters?.categoria?.categoriaId) {
    paramsFilter = {
      ...paramsFilter,
      categoriaId: filters.categoria.categoriaId,
    }
  }
  return paramsFilter
}

const listSCustomSwot = (e, nls) => {

  const labelSwots = {
    forca: nls.forca, //'Força',
    ameaca: nls.ameaca, //'Ameaça',
    oportunidade: nls.oportunidade, //'Oportunidade',
    fraqueza: nls.fraqueza, // 'Fraqueza',

    insatisfacaoGeral: nls.insatisfacaogeral, //'Insatisfação geral',
    satisfacaoGeral: nls.satisfacaogeral, //'Satisfação geral',
    preco: nls.preco, //'Preço',
    atendimento: nls.atendimento, //'Atendimento',
    produto: nls.produto, //'Produto',
    indicadores: nls.indicadores, //'Indicadores',
    loja: nls.loja, //'Loja',
    //fraqueza: 'Fraqueza',
    //fraqueza: 'Fraqueza',
    
  }

  const swotList = Object.keys(e).filter(f=> f !== 'qtdeAvaliacoes').map(swot=> {
    return {
      id: swot, 
      title: labelSwots[swot],
      swot:  Object.keys(e[swot]).map(s=> {
        let infoResp = e[swot][s]
        if (typeof e[swot][s] === 'string') {
          infoResp = e[swot][s].split(',')
        }
        return {
          id: s,
          label: labelSwots[s],
          info: infoResp
        }
      }), 
    }
  })
  return swotList
}

export const listarSwots = ({
  projetoID,
  empresaId,
  grupo,
  filters={}, 
  typeSelect={}, 
  paginate = {pageNumber: 1, perPage: 7},
  idioma,
  nls
}) => {
  return dispatch => {
    dispatch(setLoadList(true))
    
    let params = paramsApi({
      projetoId: projetoID,
      // PageAtual: paginate.pageNumber?paginate.pageNumber:1,
      // RPP: paginate.perPage?paginate.perPage:7,
      ...grupo?.name?{[ grupo?.nomeGrupoProduto ? 'grupoProdutoId' : 'grupoLojaId']: grupo?.id}:{},
      empresaId,
      ...filters,
      idioma
    })

    apiOld.get(`/Front/GetSwotUnificada${params}`)
    .then(result => {
      const initialStatePaginate = { 
        pageNumber: result.data.pageAtual, 
        totalPages: result.data.totalPaginas,  
        totalElements: result.data.totalRegistros, 
        perPage: result.data.rpp,
      }
      dispatch([
        // setListarSwots(listasMock.swots),
        setListarSwots(listSCustomSwot(result.data, nls)),
        // setPaginateComentarios(initialStatePaginate),
        setTotalAvaliacao(result.data.qtdeAvaliacoes),
        setLoadList(false)
      ])
    })
    .catch(err => {
        console.error(err);
        const initialStatePaginate = { 
          pageNumber: 1, 
          totalPages: 1,  
          totalElements: 0, 
          perPage: paginate.perPage
        }
        dispatch([
          setListarSwots([]),
          // setPaginateComentarios(initialStatePaginate),
          setTotalAvaliacao(0),
          setLoadList(false)
        ])
    });
  }
}

export const filterHandle = ({
  projetoID, 
  empresaId,
  grupo,
  params, 
  filtersProvisorio, 
  typeSelect, 
  paginate,
  idioma,
  nls
}) => {
  const paramsCustom = {...params, ciclo: params?.ciclo?.ano ? params.ciclo : filtersProvisorio.ciclo}
  let paramsFilter = filtersCustom({filters: paramsCustom, typeSelect})
  return dispatch => dispatch([
    setFiltered(paramsCustom),
    listarSwots({projetoID, empresaId, grupo, filters: paramsFilter, paginate, typeSelect, idioma, nls}),
  ])
}