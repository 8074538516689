import React, { useEffect } from 'react'
import { Router, Route, Switch, HashRouter } from 'react-router-dom';
import { history } from '../helpers/history';
import AuthLayout from '../layout/layout';
import { getIp, titlePage } from '../layout/redux/layoutActions';
import { useDispatch, useSelector } from 'react-redux';

import paginaAlerta from './alertPage';
import errorPage from './errorPage';
import emDesenvolvimento from './emDesenvolvimento';
import login from '../modules/login/login';
import recupararSenha from '../modules/login/recupararSenha';


import projetosLoja from '../modules/publica/projetos/projetos';
import calculadoraAdquirir from '../modules/publica/calculadora/adquirirAtivos';


import basededados from '../modules/basededados/basededados';

import csatcliente from '../modules/empresa/csatcliente/csatcliente';
import npscliente from '../modules/empresa/npscliente/npscliente';
import rankingclientecsat from '../modules/empresa/rankingclientecsat/rankingclientecsat';
import rankingclientenps from '../modules/empresa/rankingclientenps/rankingclientenps';
import csatconcorrentes from '../modules/empresa/csatconcorrentes/csatconcorrentes';
import indicadoresEmpresas from '../modules/empresa/indicadores/indicadores';
import analiseswotunificada from '../modules/empresa/swot/swot';

import infoGrupo from '../modules/grupos/infoGrupo/infoGrupo';
import rankingcsat from '../modules/grupos/rankingcsat/rankingcsat';
import rankingnps from '../modules/grupos/rankingnps/rankingnps';
import indicadores from '../modules/grupos/indicadores/indicadores';
import swot from '../modules/grupos/swot/swot';

import comentarios from '../modules/comentarios/comentarios';
import npsconcorrentes from '../modules/empresa/npsconcorrentes/npsconcorrentes';
import meusProjetos from '../modules/meusProjetos/meusProjetos';
import configurar from '../modules/meusProjetos/configurar/configurar';
import usuarios from '../modules/usuarios/usuarios';
import projetos from '../modules/projetos/projetos';
import cadastro from '../modules/login/cadastro';
import redescsatcliente from '../modules/redes/csatcliente/csatcliente';
import redesnpscliente from '../modules/redes/npscliente/npscliente';
import redescsatconcorrentes from '../modules/redes/csatconcorrentes/csatconcorrentesredes';
import redesnpsconcorrentes from '../modules/redes/npsconcorrentes/npsconcorrentesredes';
import indicadoresredes from '../modules/redes/indicadores/indicadores';


//PRODUTOS COMENTARIOS
import produtoscsatcliente from '../modules/produtos/csatcliente/csatcliente';
import produtosnpscliente from '../modules/produtos/npscliente/npscliente';
import produtoscsatconcorrentes from '../modules/produtos/csatconcorrentes/csatconcorrentesprodutos';
import produtosnpsconcorrentes from '../modules/produtos/npsconcorrentes/npsconcorrentesprodutos';
import indicadoresprodutos from '../modules/produtos/indicadores/indicadores';
import swotprodutos from '../modules/produtos/swot/swot';
import infoGrupoProdutos from '../modules/produtos/infoGrupo/infoGrupo';
import comentariosnovos from '../modules/comentnovo/comentarios';
import plano7dias from '../modules/planos/plano7dias/plano7dias';

import minhasAssinaturas from '../modules/minhasAssinaturas/minhasAssinaturas';
import upgradeConta from '../modules/upgradeConta/upgradeConta';
import cadastroConvite from '../modules/login/cadastro-convite';
import questionario from '../modules/questionario/questionario';

const PrivateRoute = ({ component: Component, path, sidebar=false }) => {
  const dispatch = useDispatch()
  const pathname =  window.location.pathname
  
  const scrollToTop = () => {
    const content = document.getElementById('box-container')
    content?.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  };

  useEffect(()=> {
    dispatch(titlePage({pathname}))
    // dispatch(titlePage({pathname, menuItens: menuItens()}))
    scrollToTop()
  },[pathname])

  return (
    <Route
      exact
      path={path}
      render={props =>
        <AuthLayout sidebar={sidebar}>
          <Component {...props} />
        </AuthLayout>
      }
    />
  )
}

export default () => {

  return (
    <Router history={history}>
      <Switch>
        <Route path='/' exact component={login} />
        <Route path='/login' exact component={login} />
        <Route path='/cadastro' exact component={cadastro} />
        <Route path='/cadastroConvite' exact component={cadastroConvite} />
        <Route path='/recuperarsenha' exact component={recupararSenha} />
        <Route path='/recuperarsenha/:tolkenRecuperar' exact component={recupararSenha} />
        
        <Route path='/ResetPassword' exact component={recupararSenha} />
        <Route path='/ResetPassword/:tolkenRecuperar' exact component={recupararSenha} />
        
        <Route path='/questionario' exact component={questionario} />


        <Route path='/lojaprojetos' exact component={projetosLoja} />
        <Route path='/calculadoraAdquirir' exact component={calculadoraAdquirir} />
        
        

        <PrivateRoute path='/checkoutsucesso' exact component={paginaAlerta} />

        <PrivateRoute path='/meusprojetos' exact component={meusProjetos} />
        <PrivateRoute path='/meusprojetos/:idProjetos' exact component={meusProjetos} />
        <PrivateRoute path='/meusprojetos/:idProjetos/configurar' exact component={configurar} />
        <PrivateRoute path='/meusprojetos/:idProjetos/configurar/:idPasso' exact component={configurar} />

        
        <PrivateRoute path='/minhasassinaruras' exact component={minhasAssinaturas} />
        <PrivateRoute path='/upgradeconta' exact component={upgradeConta} />

        <PrivateRoute path='/usuarios' exact component={usuarios} />
        <PrivateRoute path='/projetos' exact component={projetos} />




        <PrivateRoute path='/meusprojetos/:idProjetos/resumoprojeto' exact component={basededados} sidebar={true} />

        <PrivateRoute path='/meusprojetos/:idProjetos/csat' exact component={csatcliente} sidebar={true} />
        <PrivateRoute path='/meusprojetos/:idProjetos/nps' exact component={npscliente} sidebar={true} />
        <PrivateRoute path='/meusprojetos/:idProjetos/csatestabelecimentos' exact component={rankingclientecsat} sidebar={true} />
        <PrivateRoute path='/meusprojetos/:idProjetos/npsestabelecimentos' exact component={rankingclientenps} sidebar={true} />
        <PrivateRoute path='/meusprojetos/:idProjetos/csatconcorrentes' exact component={csatconcorrentes} sidebar={true} />
        <PrivateRoute path='/meusprojetos/:idProjetos/npstconcorrentes' exact component={npsconcorrentes} sidebar={true} />
        
        <PrivateRoute path='/meusprojetos/:idProjetos/csatredescsatcliente' exact component={redescsatcliente} sidebar={true} />
        <PrivateRoute path='/meusprojetos/:idProjetos/npsredescsatcliente' exact component={redesnpscliente} sidebar={true} />
        <PrivateRoute path='/meusprojetos/:idProjetos/csatredesconcorrentes' exact component={redescsatconcorrentes} sidebar={true} />
        <PrivateRoute path='/meusprojetos/:idProjetos/npsredesconcorrentes' exact component={redesnpsconcorrentes} sidebar={true} />
        <PrivateRoute path='/meusprojetos/:idProjetos/indicadoresempresas' exact component={indicadoresEmpresas} sidebar={true} />
        <PrivateRoute path='/meusprojetos/:idProjetos/analiseswotunificada' exact component={analiseswotunificada} sidebar={true} />


        <PrivateRoute path='/meusprojetos/:idProjetos/indicadoresredes' exact component={indicadoresredes} sidebar={true} />

        {/* PRODUTOS */}
        <PrivateRoute path='/meusprojetos/:idProjetos/csatprodutoscsatcliente' exact component={produtoscsatcliente} sidebar={true} />
        <PrivateRoute path='/meusprojetos/:idProjetos/npsprodutosnpscliente' exact component={produtosnpscliente} sidebar={true} />
        <PrivateRoute path='/meusprojetos/:idProjetos/csatprodutosconcorrentes' exact component={produtoscsatconcorrentes} sidebar={true} />
        <PrivateRoute path='/meusprojetos/:idProjetos/npsprodutosconcorrentes' exact component={produtosnpsconcorrentes} sidebar={true} />
        <PrivateRoute path='/meusprojetos/:idProjetos/indicadoresprodutos' exact component={indicadoresprodutos} sidebar={true} />
        <PrivateRoute path='/meusprojetos/:idProjetos/analiseswotprodutos' exact component={swotprodutos} sidebar={true} />
        <PrivateRoute path='/meusprojetos/:idProjetos/analiseswotprodutos/:IdSwot' exact component={swotprodutos} sidebar={true} />
        <PrivateRoute path='/meusprojetos/:idProjetos/resumogruposprodutos' exact component={infoGrupoProdutos} sidebar={true} />

        {/* COMENTÁRIOS */}
        <PrivateRoute path='/meusprojetos/:idProjetos/comentariosnovos' exact component={comentariosnovos} sidebar={true} />

        {/* PLANOS */}
        <PrivateRoute path='/meusprojetos/:idProjetos/pl7' exact component={plano7dias} sidebar={true} />



        <PrivateRoute path='/meusprojetos/:idProjetos/resumogrupos' exact component={infoGrupo} sidebar={true} />
        <PrivateRoute path='/meusprojetos/:idProjetos/csatestabelecimentosconcorrentes' exact component={rankingcsat} sidebar={true} />
        <PrivateRoute path='/meusprojetos/:idProjetos/npsestabelecimentosconcorrentes' exact component={rankingnps} sidebar={true} />
        <PrivateRoute path='/meusprojetos/:idProjetos/indicadores' exact component={indicadores} sidebar={true} />
        <PrivateRoute path='/meusprojetos/:idProjetos/analiseswot' exact component={swot} sidebar={true} />
        <PrivateRoute path='/meusprojetos/:idProjetos/analiseswot/:IdSwot' exact component={swot} sidebar={true} />
        
        <PrivateRoute path='/meusprojetos/:idProjetos/comentarios' exact component={comentarios} sidebar={true} />

        <PrivateRoute path='/grupo1' exact component={emDesenvolvimento} sidebar={true} />



        <Route component={errorPage} />
      </Switch>
    </Router>
  )
}