import { dateCustom } from '../../../../components';
import { numberZero, paramsApi } from '../../../../helpers';
import api from '../../../../helpers/api';
import apiOld from '../../../../helpers/apiOld';
import { setFiltered } from '../../../../layout/redux/layoutActions';

const  generateDataName = (name, yrange) => {
    let y = Math.floor(Math.random() * (yrange.max - yrange.min + 1)) + yrange.min;
    return { x: name, y: y };
}
const nLojas = 320
const listGraficos = [
  {
    id: 'indicadores1',
    title: 'Preço',
    labels: [ 'Preço Alto', 'Oferta Ruim', 'Preço Errado', 'Mais Caro que Concorrências', 'Sem Preço em Alguns Produtos' ],
    labelsValor: Array(5).fill().map(()=> Math.floor(Math.random() * (100 - 0 + 1)) + 0),
    type: { min: 0, max: 100 },
    lojas: Array(nLojas).fill().map((_, i)=> `Loja ${i+1}`)
  },
  {
    id: 'indicadores2',
    title: 'Produto',
    labels: ['Qualidade Ruim', 'Qualidade Ruim FLV', 'Qualidade Ruim Açougue', 'Variedade Ruim', 'Marcas Ruins' ],
    labelsValor: Array(5).fill().map(()=> Math.floor(Math.random() * (100 - 0 + 1)) + 0),
    type: { min: 0, max: 100 },
    lojas: Array(nLojas).fill().map((_, i)=> `Loja ${i+1}`)
  },
  {
    id: 'indicadores3',
    title: 'Atendimento', 
    labels: ['Atendimento Ruim', 'Poucos Caixas', 'Caixa Rápido', 'Caixa Lento', 'Fila no Caixa' ],
    labelsValor: Array(5).fill().map(()=> Math.floor(Math.random() * (100 - 0 + 1)) + 0),
    type: { min: 0, max:100 },
    lojas: Array(nLojas).fill().map((_, i)=> `Loja ${i+1}`)
  },
  {
    id: 'indicadores4',
    title: 'Loja',
    labels: ['Suja', 'Precisa de Reforma', 'Mal Organizada', 'Estacionamento Ruim', 'Cheia' ],
    labelsValor: Array(5).fill().map(()=> Math.floor(Math.random() * (100 - 0 + 1)) + 0),
    type: { min: 0, max: 100 },
    lojas: Array(nLojas).fill().map((_, i)=> `Loja ${i+1}`)
  }
]

const listasMock = {
  graficos: listGraficos.map(l=> ({
    id: l.id,
    title: l.title,
    grafico: l.labels.map((label, i)=> ({
      name: label,
      labelsValor: l.labelsValor[i],
      data: l.lojas.map(loja=> {
        return generateDataName(loja,{ min: 0, max: 100 })
      })
    }))
  }))
}



export const setTotalAvaliacao = payload => ({
  type: 'INFO_GRAFICO_INDICADORES_TOTAL_AVALIACAO',
  payload
})

export const setFiltersProvisorio = payload => ({
  type: 'INFO_FILTERS_INDICADORES',
  payload
})

export const putFiltersProvisorio = payload => ({
  type: 'CHANGE_FILTERS_INDICADORES',
  payload
})

export const cleanFiltersProvisorio = () => ({
  type: 'CLEAN_FILTERS_INDICADORES',
})


export const setGraficos = payload => ({
  type: 'INFO_GRAFICOS_INDICADORES',
  payload
})

export const setGraficosDefault = payload => ({
  type: 'INFO_GRAFICOS_INDICADORES_DEFAULT',
  payload
})


export const carregarGrafico = ({
  projetoID, 
  empresaId,
  grupo, 
  filters={}, 
  typeSelect={},
  idioma
}) => {

  return dispatch => {
    let paramsFilter = filtersCustom({filters, typeSelect})
    let params = paramsApi({
      projetoId: projetoID,
      grupo: grupo?.name,
      // intervalo: '2023/01/01 2023/08/01',
      // categoriaId: 1
      ...paramsFilter,
      idioma,
    })

    apiOld.get(`/Front/GetDadosCategoriasIndicadores${params}`)
    .then(result => { 
      dispatch([
        setGraficos(result.data.listaDeIndicadoresCategoria),
        result.data.listaDeIndicadoresCategoria.length > 1?setGraficosDefault(result.data.listaDeIndicadoresCategoria):null,
        setTotalAvaliacao(result.data.qtdeAvaliacoes)
      ])
    })
    .catch(err => {
        console.error(err);
    });
  }
}

export const filterHandle = ({
  projetoID, 
  empresaId,
  grupo, 
  params, 
  typeSelect, 
  filtersProvisorio,
  idioma
}) => {
  const paramsCustom = {...params, ciclo: params?.ciclo?.ano ? params.ciclo : filtersProvisorio.ciclo}
  return dispatch => dispatch([
    setGraficos([]),
    setFiltered(paramsCustom),
    carregarGrafico({projetoID, empresaId, grupo, filters: paramsCustom, typeSelect, idioma}),
  ])
}

export const filtersCustom = ({filters, typeSelect}) => {
  let paramsFilter = {}
  const dataAtual = new Date()
  if (typeSelect?.id === 'Ciclo') {
    if (filters?.ciclo?.mesAno) {
      paramsFilter = {
        intervalo: `${filters.ciclo.ano}-${filters.ciclo.mes}`,
      }
    }
  }
  if (typeSelect?.id === 'Data') {
    paramsFilter = {
      intervalo: `${dateCustom(filters.dataInicial).data} ${dateCustom(filters.dataFinal).data}`
    }
  }
  if (filters?.categoria?.id) {
    paramsFilter = {
      ...paramsFilter,
      categoriaId: `${filters.categoria.id}`,
    }
  }
  return paramsFilter
}