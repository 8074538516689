import { useState, useRef, useEffect, ReactNode } from 'react';
import useOutsideClick from '../../useOusideClick/useoutsideclick';
import { IcoClose, IcoSearch } from '../../icon/icon';
import { validarCampo } from '../../validation/Validation';
import Portal from '../../portal/portal';
import { Button } from '../../index';

const chargeDefault = { max: 0, text: 'Mais itens', action: () => null };
const textCustomDefault = {selecione: 'Selecione', selecionado: 'Selecionado',  selecionados: 'Selecionados',  todos: 'Marcar todos'}

export const Select = ({
  options,
  action,
  actionClose,
  actionFilter,
  selected,
  label,
  name,
  color = '',
  closeOnSelect = null,
  multiSelect = false,
  disabled = false,
  selectedItem = true,
  textCustom = textCustomDefault,
  filter,
  charge = chargeDefault,
  optionLabel = 'name',
  optionValue = 'id',
  optionCustom,
  labelCustom,
  required,
  ...props
}) => {
  closeOnSelect =
    closeOnSelect === null ? (multiSelect ? false : true) : closeOnSelect;
  const [selectOpen, setSelectOpen] = useState(false);
  const [selectCoordinates, setSelectCoordinates] = useState({});
  const [selectState, setSelectState] = useState([]);

  const openSelect = ({ elem, value }) => {
    if (elem) {
      let div = elem.target;
      let rect = div.getBoundingClientRect(),
        eixoX = rect.left,
        eixoY = rect.top,
        width = rect.width,
        height = rect.height;

      // let windowWidth = window.innerWidth;
      let windowHeight = window.innerHeight;

      let coor = {};
      if (Math.ceil(eixoY + height) + 300 >= windowHeight) {
        coor = { left: eixoX, bottom: windowHeight - eixoY, top: 'auto' };
      } else {
        coor = { left: eixoX , top: eixoY + height + 1 };
      }
      setSelectCoordinates({ ...coor, position: 'absolute', minWidth: width, width: 'auto' });
    } else {
      setSelectCoordinates({})
    }
    setSelectOpen(value);
  };

  const ref = useRef(null);
  useOutsideClick(ref, (e) => {
    // openSelect({value: e})
    if (!e && selectOpen === true) {
      closeAction({ ...selected, ...selectState });
    }
  });

  useEffect(() => {
    setSelectState(options ? options : []);
  }, [options]);

  const veryfiMultiSelect = (e) => {
    const verify = selected.filter((elem) => {
      return elem[optionValue] === e[optionValue] ? elem : null;
    });

    const res = selected.filter((elem) => {
      return elem[optionValue] !== e[optionValue] ? elem : null;
    });

    if (selected.length === 0) {
      return [e];
    } else {
      if (verify.length === 0) {
        return selected.concat(e);
      } else {
        return res;
      }
    }
  };

  const selectAction = (e) => {
    const resp = multiSelect ? (e ? veryfiMultiSelect(e) : []) : e ? e : {};
    action({ name: name, value: resp, type: 'select' });
  };

  const textButton = (e) => {
    if (!multiSelect) {
      const verifyObject = Object.keys(e?e:{});
      if (labelCustom) {
        return labelCustom(e?e:{});
      } else if (e && e[optionLabel]) {
        return e[optionLabel];
      } else {
        return verifyObject.length ? e : textCustom.selecione;
      }
    } else {
      if (e.length === 0) {
        return textCustom.selecione;
      } else if (e.length === 1) {
        return `${textCustom.selecionado} - ${e[0][optionLabel]} `;
      } else {
        return `${textCustom.selecionados} ( ${e.length} ) `;
      }
    }
  };

  const veryfiSelected = (e) => {
    if (!multiSelect) {
      return selected[optionValue] === e[optionValue] ? true : false;
    } else {
      if (selected.length === 0) {
        return false;
      } else {
        const verify = selected.filter((elem) => {
          return elem[optionValue] === e[optionValue] ? elem : null;
        });

        if (verify.length === 0) {
          return false;
        } else {
          return (verify[0] && verify[0][optionValue]) === e[optionValue]
            ? true
            : false;
        }
      }
    }
  };

  const selectAll = (e) => {
    action({ name: name, value: e ? options : [], type: 'select' });
  };

  const closeAction = (e) => {
    e = closeOnSelect && multiSelect ? selected : e;
    const resp = multiSelect ? (e ? e : []) : e ? e : {};
    const v = require
      ? validarCampo({
          name,
          value: resp,
          pattern: multiSelect ? 'multiselect' : 'select',
        })
      : {};
    if (actionClose) {
      actionClose(resp, v);
    }
    openSelect({ value: false });
  };

  const require = required ? Object.keys(required) : undefined;

  return (
    <div
    {...props}
      className={`form-box form-select-box ${color} ${
        require && (required && (required.erro[name] ? 'erro' : ''))
      } `}
    >
      {label ? (
        <label className="label-input" htmlFor={`id-${name}`}>
          {require ? <span className='required-label'>*</span> : ''} {label}
        </label>
      ) : null}
      <div>
        <Button
          className={`select-selected ${selectOpen ? 'open' : ''}`}
          onClick={(e) =>
            openSelect({ elem: e, value: !disabled ? !selectOpen : false })
          }
        >
          {textButton(selected)}
        </Button>
        <Portal name="select">
          {selectOpen ? (
            <div
              ref={ref}
              className={`select-box select-${name} ${
                multiSelect ? 'multiselect' : ''
              }`}
              style={selectCoordinates}
            >
              {filter && actionFilter ? (
                <FilterSelect
                  clean={filter.clean}
                  action={(e) => [
                    setSelectState(FilterAction(options, e)),
                    actionFilter(options, e),
                  ]}
                  filter={filter.text}
                  title={filter.title}
                  text={filter.text}
                />
              ) : null}

              {multiSelect ? (
                <div
                  className={`select-all ${
                    selected.length > 0 && selected.length === options.length
                      ? 'selected'
                      : ''
                  }`}
                  onClick={() => [
                    selectAll(selected.length !== options.length ? true : ''),
                    closeOnSelect ? closeAction(selected) : null,
                  ]}
                >
                  <span className="checkelement"></span>
                  {textCustom.todos}
                </div>
              ) : null}

              <div className="select-options">
                {!multiSelect && selectedItem ? (
                  <div
                    className={selected == {} ? 'selected' : ''}
                    onClick={(e) => [
                      selectAction(''),
                      closeOnSelect ? closeAction('') : null,
                    ]}
                  >
                    {textCustom.selecione}
                  </div>
                ) : null}

                {selectState.map((e, i) => {
                  return (
                    <div
                      className={
                        veryfiSelected(selectState[i]) ? 'selected' : ''
                      }
                      key={`${name}-${e[optionValue]}-${i}`}
                      onClick={(e) => [
                        selectAction(selectState[i]),
                        closeOnSelect ? closeAction(selectState[i]) : null,
                      ]}
                    >
                      {multiSelect ? (
                        <span className="checkelement"></span>
                      ) : null}
                      {optionCustom ? optionCustom(e) : e[optionLabel]}
                    </div>
                  );
                })}
                {charge.max && !(selectState.length === charge.max) ? (
                  <Button
                    className="btn primary normal block"
                    onClick={charge.action}
                    title={charge.text}
                  >
                    {charge.text}
                  </Button>
                ) : null}
              </div>
            </div>
          ) : null}
        </Portal>
      </div>

      {required?.erro?.[name] ? (
        <span className="campo-obrigatorio">{required.message}</span>
      ) : null}
    </div>
  );
};

export const FilterAction = (d, e, n = 'name') => {
  return d.filter((i) => (e !== '' ? ([i[n]].includes(e) ? i : null) : i));
};

export const FilterSelect = ({
  action = (params) => null,
  title = 'Filtrar',
  clean = <IcoClose />,
  filter = <IcoSearch />,
}) => {
  const [filterState, setFilterState] = useState('');
  const cleanFilter = () => {
    setFilterState('');
  };
  const onActionFilter = (e) => {
    setFilterState(e);
    if (e.length >= 4) {
      action(e);
    }
  };
  return (
    <div className="select-filter">
      <div className="input-actions">
        <Button onClick={() => action(filterState)} title={title}>
          {filter}
        </Button>
      </div>
      <input
        type="text"
        name="filter-select"
        id={`id-filter-select`}
        value={filterState}
        onChange={(e) => onActionFilter(e.target.value)}
        placeholder={title}
      />
      <div className="input-actions">
        <Button
          className={filterState === '' ? 'hidden' : ''}
          onClick={() => [cleanFilter(), action('')]}
        >
          {clean}
        </Button>
      </div>
    </div>
  );
};

export const verifySelectValue = (e) => {
  if (Array.isArray(e.value)) {
    const val = e.value[0];
    const sel = e.list.filter(
      (v) => v[e.val ? e.val : 'name'] === val[e.val ? e.val : 'name']
    );
    return sel.length ? sel[0] : {};
  } else {
    const sel = e.list.filter(
      (v) => v[e.val ? e.val : 'name'] === e.value
    );
    return sel.length ? sel[0] : {};
  }
};
