import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Button, IcoDownload } from '../../../components';
import { convertChart, history } from '../../../helpers';
import { GraficoHeatmapProduto } from '../../../components/graficos/heatmapproduto';

export default () => {
  const dispatch = useDispatch()
  const { graficos } = useSelector(state=> state.indicadoresempresasState)
  const { titlePage, sistema } = useSelector(state=> state.layoutState)
  const [download, setDownload] = useState(false)

  const nls = require(`./nls/${sistema.idioma}.json`)
  const semdadosparaexibir = nls.semdadosparaexibir;


  const handleDownload = async (e) => {
    setDownload(true)
    const chartdownload = await convertChart({id: e, titlePage})
    setDownload(chartdownload)
  }

  
  return (
    <>
    {graficos?.map(grafico=>{
      //const graficoData = grafico.listaDeIndicadoresCategoriaProdutos;
      //const graficoData = grafico;
      //console.error(graficos,'GRAFICOS')
      //console.error('grafico:', graficoData);

      return <div 
        key={grafico.categoriaId}  
        id={`graficos-chart-box-${grafico.categoriaId}`}  
        className={`box-indicadores-graficos box-content ${download?'graficos-download':''}`}
      >
        <div className='graficos-chart-box-action-title'>
          {download?<h3>{titlePage} - <small>{grafico.descricaoCategoria}</small></h3>:<h3>{grafico.descricaoCategoria}</h3>}
          {grafico.listaDeIndicadoresCategoriaProdutos?
            <Button 
              type='btn circle' 
              color='primary' 
              variant='outlined' 
              onClick={()=> handleDownload(`graficos-chart-box-${grafico.categoriaId}`)} 
              title='Download gráfico'>
                <IcoDownload />
            </Button>
            :null}
        </div>
        <GraficoHeatmapProduto 
          id={grafico.categoriaId}
          grafico={grafico.listaDeIndicadoresProdutos} 
          semdadosparaexibir={semdadosparaexibir}
          hint={true}
        />
      </div>
    })}
    </>
  )
}
